import React from 'react';

import config from '../../config';

export default function Footer() {
  return (
    <header id="header">
      <h1
        style={{
          fontFamily: 'Julius Sans One',
        }}
      >
        {config.heading}
      </h1>
      <span
        style={{
          fontSize: '1.6rem',
        }}
      >
        {config.subHeading}
      </span>
    </header>
  );
}
