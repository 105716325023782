module.exports = {
  siteTitle: 'Julien - Champagne meets Redbull', // <title>
  manifestName: 'Julien Drink',
  manifestShortName: 'Julien Drink', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Julién: Champagne meets Redbull',
  subHeading: 'Ever wondered what heaven tastes like?',
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/julien_hq/',
    },
  ],
};
