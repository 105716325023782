import React, { Component } from 'react';

export default function FollowUs() {
  const style = {
    color: 'magenta',
  };
  return (
    <h3
      style={{
        paddingTop: '20px',
      }}
    >
      Follow us on Instagram:{' '}
      <a
        style={style}
        href="https://www.instagram.com/julien_hq/"
        target="_blank"
      >
        @julien_hq
      </a>
    </h3>
  );
}
